function externalLinks () {
  Array.from(document.links).forEach((el) => {
    const link = el
    if (link.hostname !== window.location.hostname) {
      link.target = '_blank'
      link.rel = 'noopener'
    }
  })
}

externalLinks()

;
import lozad from 'lozad'

function lazyLoad () {
  const isMobile = window.matchMedia('(max-width: 768px)')

  const els = document.querySelectorAll('.lazyload')
  const observer = lozad(els, {
    rootMargin: '260px',
    threshold: !isMobile.matches ? 0.2 : 0.01,
  })
  observer.observe()
}

lazyLoad()

;
function headerSticky () {
  const header = document.querySelector('header')
  const trigger = document.querySelector('.sentinal')
  const triggerWhite = document.querySelector('.sentinal-to-white')
  const heroHome = document.querySelector('.hero-home')
  let countToStart = 0

  const handler = (entries) => {
    const isBugerActive = document.getElementById('burger').checked
    if (!entries[0].isIntersecting && !isBugerActive) {
      header.classList.add('header--sticky')
      if (!heroHome) document.body.classList.add('header-is-sticky')
      else heroHome.classList.remove('initial')
      countToStart++
    } else {
      header.classList.remove('header--sticky')
      if (!heroHome) document.body.classList.remove('header-is-sticky')
      else {
        if (countToStart) heroHome.classList.add('initial-again')
        if (heroHome) window.scrollTo({ top: 0, behavior: 'smooth' })
        heroHome.classList.add('initial')
      }
    }
  }

  const handlerWhiteHeader = (entries) => {
    const isBugerActive = document.getElementById('burger').checked
    if (!entries[0].isIntersecting && !isBugerActive && window.scrollY >= window.innerHeight) {
      header.classList.add('header--sticky-white')
    } else {
      header.classList.remove('header--sticky-white')
    }
  }

  if (header) {
    // create the observer
    const observer = new window.IntersectionObserver(handler)
    const observerToWhitHeader = new window.IntersectionObserver(handlerWhiteHeader)
    // give the observer some dom nodes to keep an eye on
    observer.observe(trigger)
    if (heroHome) observerToWhitHeader.observe(triggerWhite)

    document.getElementById('burger').addEventListener('click', (e) => {
      if (e.target.checked) document.body.classList.add('overflow')
      else document.body.classList.remove('overflow')
    })
  }
}

headerSticky()

;
/* eslint-disable new-cap */
/* global autoComplete */
async function fetchPagesJSON () {
  const response = await fetch('/index.json')
  const pages = await response.json()
  const event = new CustomEvent('fetchedPages', { detail: pages })
  document.body.dispatchEvent(event)
  return pages
}

function search () {
  const search = document.getElementById('search')
  const searchInput = document.querySelector('.search__input input')
  if (search) {
    fetchPagesJSON().then(pages => {
      // eslint-disable-next-line no-new
      new autoComplete({
        selector: '.search__input input',
        placeHolder: 'Išči...',
        data: {
          src: pages.data.items,
          key: ['title'],
        },
        searchEngine: 'strict',
        mode: 'strict',
        resultItem: {
          element: 'a',
          className: 'autoComplete_result block',
          content: (item, element) => {
            element.setAttribute('href', item.value.url)
            element.innerHTML = `<span class="category">${item.value.tag}</span>${element.innerHTML}`
          },
          highlight: {
            render: true,
            className: 'autoComplete_highlighted',
          },
          selected: {
            className: 'autoComplete_selected',
          },
        },
      })

      /* on document click it closes search */
      document.addEventListener('click', function (event) {
        const isClickInside = document.querySelector('header .search').contains(event.target)
        if (search.checked && !isClickInside && event.target.id !== 'search') {
          searchInput.value = ''
          search.checked = false
          searchInput.blur()
        }
      })

      document.querySelector("input[id='search']").addEventListener('change', (e) => {
        if (e.target.checked) searchInput.focus()
        else searchInput.blur()
      })
    })
  }
}

search()
